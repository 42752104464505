import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [4,5];

export const dictionary = {
		"/(landing_page)": [7,[2]],
		"/(shop)/(duka.bio.shop)/[shop_slug]/(home)": [8,[3,4,5]],
		"/(shop)/(duka.bio.shop)/[shop_slug]/(shop)/cart": [9,[3,4,6]],
		"/(shop)/(duka.bio.shop)/[shop_slug]/(shop)/checkout": [10,[3,4,6]],
		"/(shop)/(duka.bio.shop)/[shop_slug]/(shop)/checkout/3rdparty/[checkout_status]": [~11,[3,4,6]],
		"/(shop)/(duka.bio.shop)/[shop_slug]/(shop)/orderstatus/[orderId]": [~12,[3,4,6]],
		"/(shop)/(duka.bio.shop)/[shop_slug]/(shop)/pay": [13,[3,4,6]],
		"/(shop)/(duka.bio.shop)/[shop_slug]/(shop)/product/[product_slug]": [~14,[3,4,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';